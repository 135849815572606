<template>
    <div class="analysis">
        <div class="content_box_">
            <h1 class="title_">您的婚前性格</h1>
            <div class="content_text_box_">
                <p v-html="test_info.marriage_before_xg.one"></p>
                <p v-html="test_info.marriage_before_xg.two"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的先天桃花运</h1>
            <div class="content_text_box_">
                <p style="font-weight:bold;padding-bottom:0;">正桃花分析：</p>
                <p v-html="test_info.py_status.zheng"></p>
                <p style="font-weight:bold;padding-bottom:0;">烂桃花分析：</p>
                <p v-html="test_info.py_status.fan"></p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的先天感情婚姻是怎样的</h1>
            <div class="content_text_box_">
                <p v-html="test_info.m_cate.desc"></p>
                <p style="color:#67386F;">
                    温馨提示：您的先天婚姻是根据您的出生时辰，结合易经原理，仔细分析的结果，描述的是您先天命理中婚姻感情的状况。可供您在做婚恋选择时参考，找到真正爱您的另一半。
                </p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">您的本命姻缘</h1>
            <div class="content_text_box_">
                <p v-html="test_info.bm_marriage"></p>
            </div>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/homeMarriageTest/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .analysis{
        .content_box_{
            width: 7.18rem;
            border: .03rem solid #DC5744;
            opacity: 0.7;
            border-radius: .30rem;
            padding-bottom: .6rem;
            margin: 0 auto;
            margin-bottom: .48rem;
            .title_{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                color: #C91C18;
                text-align: center;
                font-weight: bold;
                background: #fae7e5;
                margin-top: .25rem;
            }
            .content_name_text_box_{ //您的信息
                width: 100%;
                padding-left: .69rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .27rem;
                }
                .name_{
                    margin-top: .35rem;
                }
                .span_style__1{
                    .text__{
                        letter-spacing:2em
                    }
                }
            }
            .content_text_box_{
                padding: 0 .35rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .35rem;
                }
            }
            
            
        }
    }
</style>